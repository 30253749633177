<script>

import HomeSquareImage from "./components/home-square-image.vue";
import HomeSectionTitle from "./components/home-section-title.vue";

const styles = [
  {
    title: 'Nike Sneakers',
    items: [
      {
        title: 'Nike Air Max 1',
        link: '/sneaker/nike/air-max-1',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Nike Air Force',
        link: '/sneaker/nike/air-force',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Nike Dunk',
        link: '/sneaker/nike/dunk',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Nike Blazer',
        link: '/sneaker/nike/blazer',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Nike Daybreak',
        link: '/sneaker/nike/daybreak',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
    ]
  },
  {
    title: 'Adidas Sneakers',
    items: [
      {
        title: 'Adidas Yeezy',
        link: '/sneaker/yeezy',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Adidas Continental 80',
        link: '/sneaker/adidas/continental80',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Adidas Ultra Boost',
        link: '/sneaker/adidas/ultra-boost',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Adidas Gazelle',
        link: '/sneaker/adidas/gazelle',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Adidas Stan Smith',
        link: '/sneaker/adidas/stan-smith',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
    ]
  },
  {
    title: 'Jordan Sneakers',
    items: [
      {
        title: 'Air Jordan 1',
        link: '/sneaker/jordan/1',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Air Jordan 5',
        link: '/sneaker/jordan/5-retro',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Air Jordan 6',
        link: '/sneaker/jordan/air-jordan-6',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Air Jordan 11',
        link: '/sneaker/jordan/11-retro',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Air Jordan 12',
        link: '/sneaker/jordan/12-retro',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
    ]
  },
  {
    title: 'Limited Edition',
    items: [
      {
        title: 'Yeezy Boost 350',
        link: '/sneaker/adidas/yeezy-boost-350',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Yeezy Boost 700',
        link: '/sneaker/adidas/yeezy-boost-700',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Virgil Abloh Off-White',
        link: '/sneaker/nike/off-white',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Converse CDG',
        link: '/sneaker/?q=converse comme des garcons',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
      {
        title: 'Nike Fear of God',
        link: '/sneaker/nike/fear-of-god',
        image: require('~/assets/images/ui/background/home-style-nike-bg.png'),
      },
    ]
  },
  
];
export default {
  name: "HomeStylesSection",
  components: {HomeSectionTitle, HomeSquareImage},

  data() {
    return {
      selectedBrandIndex: 0,
      selectedStyleIndex: 0,
      styles
    }
  },
  computed: {
    styleImage() {
      return this.styles[this.selectedBrandIndex].items[this.selectedStyleIndex].image
    },
  },
  methods: {
    selectStyle(brandIndex, styleIndex) {
      this.selectedBrandIndex = brandIndex
      this.selectedStyleIndex = styleIndex
    }
  }
}
</script>

<template>
  <section
      class="flex flex-col-reverse lg:flex-row w-full border-b border-black">
    <div class="w-full lg:basis-1/2 aspect-square flex flex-col justify-between">
      <HomeSectionTitle :title="$t('text.homePage.homeStylesSectionTitle')" :subtitle="$t('text.homePage.homeStylesSectionSubTitle')"/>
      <div class="flex flex-col w-full p-4 lg:p-8 space-y-4">
        <div v-for="(style, index) in styles" :key="index" class="flex flex-col space-y-4">
          <span class="text-back text-md font-bold">{{style.title}}</span>
          <ul class="flex flex-row flex-wrap">
            <li v-for="(item, i) in style.items" :key="i" :class="{
            'flex flex-col border-black border rounded-full mr-4 mb-4 text-black ': true,
            'bg-black text-white': selectedBrandIndex === index && selectedStyleIndex === i,
                }" @mouseover="selectStyle(index, i)">
            <nuxt-link :to="localePath(item.link)" class="text-sm px-4 py-2 no-underline">{{item.title}}</nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <HomeSquareImage position="right" :image="styleImage" k="style" />
  </section>
</template>