<script>
import {mapGetters} from "vuex";

export default {
  name: "product-thumbnail",
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    rank: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    ...mapGetters(['currency']),
    getProductPrice()
    {
      let currency = this.currency;

      let convertedPrice = this.product['price_' + currency.toLowerCase()];
      if (convertedPrice != false || convertedPrice != undefined)
        return this.$root.price(convertedPrice, currency);

      return '-';
    },
    getSalePrice()
    {
      let currency = this.currency;
      let convertedSalePrice = this.product['sale_price_' + currency.toLowerCase()];
      if (convertedSalePrice != false || convertedSalePrice != undefined)
        return this.$root.price(convertedSalePrice, currency);

      return '-';
    },
    discountRate() {
      let price = this.getProductPrice;
      let salePrice = this.getSalePrice;

      if (price && salePrice) {
        return Math.round((price - salePrice) / price * 100);
      }
      return 0;
    }
  }
}
</script>

<template>
  <nuxt-link no-prefetch :to="localePath('/product/' + product.id + '?hideCheckout=true')" class="flex flex-col relative aspect-[2/3] justify-between h-auto w-full">
    <span class="absolute top-4 left-4 z-10 text-gray-high text-xs"
    >{{product.release.sku}}</span>
    <div class="flex flex-col w-full aspect-square items-center justify-center flex-1 mt-10">
      <img v-lazy-load :data-src="product.release.image" :alt="product.release.name" class="w-full h-auto object-contain object-center"/>
    </div>
    <div class="flex flex-col w-full p-4 bg-white">
      <div class="flex flex-row w-full mb-2" v-show="rank">
        <span class="bg-primary w-[18px] h-[18px] text-white text-xs text-center pt-[2px]">{{rank}}</span>
      </div>
      <div class="flex flex-col space-y-1 flex-1">
        <span class="text-black text-xs">{{product.release.brand}}</span>
        <span class="text-black text-xs text-ellipsis text-nowrap max-w-[150px] lg:max-w-[200px] overflow-hidden">{{product.release.name}}</span>
        <span class="text-black text-xs">
          <span v-if="discountRate" class="text-danger mr-1 font-semibold">{{discountRate}}%</span>
          <span class="text-xs">{{getProductPrice}}</span>
        </span>
      </div>
    </div>
  </nuxt-link>
</template>

<style scoped>

</style>