var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full border-t border-[#131416] md:border-t-none"},[_c('div',{ref:"emblaRef",staticClass:"embla overflow-hidden"},[_c('div',{staticClass:"embla__container flex flex-row w-full flex-nowrap"},_vm._l((_vm.products),function(product,i){return _c('div',{key:i,class:{
        'embla__slide flex-none aspect-square border-r border-[#131416] last:border-r-0': true,

        'basis-1/6 lg:basis-1/6': _vm.cols === 6,
        'basis-1/5 lg:basis-1/5': _vm.cols === 5,
        'basis-1/2 lg:basis-1/4': _vm.cols === 4,
        'basis-1/3 lg:basis-1/3': _vm.cols === 3,
        'basis-1/2 lg:basis-1/2': _vm.cols === 2,
        'basis-1/1 lg:basis-1/1': _vm.cols === 1,
      }},[_c('ProductThumbnail',{attrs:{"product":product,"rank":_vm.rank ? i + 1 : 0}})],1)}),0)]),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.canPrev),expression:"canPrev"}],staticClass:"absolute h-8 w-8 rounded-full left-0 top-1/2 -translate-y-1/2",on:{"click":_vm.scrollPrev}},[_c('img',{staticClass:"h-8 w-8 transform rotate-180",attrs:{"src":require("assets/images/ui/home-swiper-next.png"),"alt":"Previous"}})]),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.canNext),expression:"canNext"}],staticClass:"absolute rounded-full right-0 top-1/2 -translate-y-1/2",on:{"click":_vm.scrollNext}},[_c('img',{staticClass:"h-8 w-8",attrs:{"src":require("assets/images/ui/home-swiper-next.png"),"alt":"Previous"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }