<template>
  <div class="flex flex-col w-full">
    <ui-breadcrumbs :items="breadcrumbItems"></ui-breadcrumbs>
    <div class="flex flex-col items-center justify-center gap-4 border-b border-black pb-4">
      <article v-if="!_.isEmpty(shop)" class="flex flex-col w-full">
        <div class="flex flex-col lg:flex-row items-stretch justify-between border-b border-black w-full">
          <div class="flex flex-col flex-1 justify-between items-start">
            <div class="flex flex-col flex-1 items-start justify-start p-6">
              <h1 class="text-xl font-bold pb-2">
                {{shop.name}}
              </h1>
              <h2 class="text-sm" v-html="description"></h2>
            </div>
            <div class="flex flex-col items-start justify-start bg-black text-white w-full p-6">
              <h3 class="country">{{$t('countries-abbr.' + shop.country)}}</h3>
              <span v-html="shop.address"></span>
            </div>
          </div>
          <div class="flex flex-col flex-1 items-center justify-center aspect-square border-l border-black text-[0px] box-content	" v-if="hasImage">
            <img :data-src="firstImage" v-lazy-load :alt="shop.name + ' photo'" class="min-w-full max-w-full aspect-square object-cover"/>
          </div>
        </div>
      </article>

      <section v-if="!_.isEmpty(shop.brands)" class="flex flex-col w-full border-b border-black">
        <div class="flex flex-col w-full pt-6 px-4 border-b border-black">
          <h2 class="mb-3 text-xl text-black font-bold">{{ $t('our-brands') }}</h2>
        </div>
        <div class="relative w-full">
          <div ref="emblaBrandRef" class="embla overflow-hidden">
            <div class="embla__container flex flex-row w-full flex-nowrap">
              <div class="embla__slide flex-none basis-1/2 lg:basis-1/4 aspect-square border-r border-[#131416] last:border-r-0"
                   v-for="(brand, i) in shop.brands" :key="i" >
                <a :href="sneakerLocalePath(_.kebabCase(brand))"
                   class="flex items-center justify-center w-full h-full">
                  <img :src="svg(brand)" :alt="brand" class="w-40 h-40 object-contain"/>
                </a>
              </div>
            </div>
          </div>
          <button @click="scrollBrandPrev" class="absolute  h-8 w-8 rounded-full left-0 top-1/2 -translate-y-1/2" v-show="canBrandPrev">
            <img src="~/assets/images/ui/home-swiper-next.png" alt="Previous" class="h-8 w-8 transform rotate-180"/>
          </button>
          <button @click="scrollBrandNext" class="absolute   rounded-full right-0 top-1/2 -translate-y-1/2" v-show="canBrandNext">
            <img src="~/assets/images/ui/home-swiper-next.png" alt="Previous" class="h-8 w-8"/>
          </button>
        </div>
      </section>


      <section v-if="!_.isEmpty(shop.sneakers)" class="flex flex-col w-full border-b border-black">
        <div class="flex flex-col w-full pt-6 px-4 border-b border-black">
          <h2 class="mb-3 text-xl text-black font-bold">{{ $t('Products in our shop') }}</h2>
        </div>
        <div class="relative w-full">
          <div ref="emblaProductRef" class="embla overflow-hidden">
            <div class="embla__container flex flex-row w-full flex-nowrap">
              <div class="embla__slide flex-none basis-1/2 lg:basis-1/4 aspect-square border-r border-[#131416] last:border-r-0"
                   v-for="(sneaker, i) in shop.sneakers" :key="i" >
                <release-thumbnail :release="sneaker"/>
              </div>
            </div>
          </div>
          <button @click="scrollProductPrev" class="absolute  h-8 w-8 rounded-full left-0 top-1/2 -translate-y-1/2" v-show="canProductPrev">
            <img src="~/assets/images/ui/home-swiper-next.png" alt="Previous" class="h-8 w-8 transform rotate-180"/>
          </button>
          <button @click="scrollProductNext" class="absolute   rounded-full right-0 top-1/2 -translate-y-1/2" v-show="canProductNext">
            <img src="~/assets/images/ui/home-swiper-next.png" alt="Previous" class="h-8 w-8"/>
          </button>
        </div>
      </section>

    </div>
  </div>
<!--    <div>-->
<!--&lt;!&ndash;        <simple-header/>&ndash;&gt;-->
<!--        <div class="container-fluid line shops-breadcrumbs">-->
<!--            <breadcrumbs :items="breadcrumbItems"></breadcrumbs>-->
<!--        </div>-->
<!--        <article class="shopinfo line" id="shopinfo" v-if="!_.isEmpty(shop)">-->
<!--            <div class="top">-->
<!--                <div class="left-top" :style="{width: !hasImage ? '100%' : ''}">-->
<!--                    <div class="wraper wraper-top">-->
<!--                        <h1 class="name">{{shop.name}}</h1>-->
<!--&lt;!&ndash;                        <client-only>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="d-flex flex-wrap align-items-center rating-container">&ndash;&gt;-->
<!--&lt;!&ndash;                                <span class="rating-value">{{ shop.rating }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                <star-rating class="d-inline-flex" @rating-selected="setRating" v-bind:read-only="!canUserRate(shop.id)"&ndash;&gt;-->
<!--&lt;!&ndash;                                             v-bind:round-start-rating="false"&ndash;&gt;-->
<!--&lt;!&ndash;                                             v-bind:star-size="30" v-bind:show-rating="false" v-model="rating"></star-rating>&ndash;&gt;-->
<!--&lt;!&ndash;                                <span class="align-self-end rating-text">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <span v-if="shop.rating != 0">&ndash;&gt;-->
<!--&lt;!&ndash;                                        ({{ shop.reviewsCount }} {{ $t('shop-rating')}})&ndash;&gt;-->
<!--&lt;!&ndash;                                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <span v-else>{{ $t('evaluate-first')}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                <span class="mt-2 color-blue w-100" v-if="ratingInfo">{{ ratingInfo }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </client-only>&ndash;&gt;-->
<!--                        <h2 class="description" v-html="description"></h2>-->
<!--&lt;!&ndash;                        <arrow-right path-class/>&ndash;&gt;-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="right-top" v-if="hasImage">-->
<!--                  <img class="shop-image" :data-src="firstImage" v-lazy-load  :alt="shop.name + ' photo'">-->
<!--&lt;!&ndash;                    <div class="swiper-container">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="swiper-wrapper">&ndash;&gt;-->
<!--&lt;!&ndash;                            <template v-for="(image, index) in ['https://i.ibb.co/ZVfGdqd/43einhalb.png']">&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="swiper-slide"><img class="slide-img"&ndash;&gt;-->
<!--&lt;!&ndash;                                                               :data-src="image" v-lazy-load :alt="shop.name + ' photo ' + index"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                            </template>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="swiper-pagination"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="bottom">-->
<!--                <div class="left-bottom" :style="{width: !hasImage ? '100%' : ''}">-->
<!--                    <div class="wraper">-->
<!--                        <h3 class="country">{{$t('countries-abbr.' + shop.country)}}</h3>-->
<!--                        <span v-html="shop.address"></span>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="right-bottom" v-if="hasImage"></div>-->
<!--            </div>-->
<!--        </article>-->

<!--        <section class="brands-container" v-if="!_.isEmpty(shop.brands)">-->
<!--            <div class="row line">-->
<!--                <h2 class="col-12 primary-title mb-3">{{ $t('our-brands') }}</h2>-->
<!--            </div>-->
<!--            <div class="row line justify-content-center">-->
<!--                <template v-for="(brand) in shop.brands">-->
<!--                    <div class="brand-logo-wrap">-->
<!--                        <l-img imgClass="brand-logo-img" :src="svg(brand)" :alt="brand"/>-->
<!--                    </div>-->
<!--                </template>-->
<!--            </div>-->
<!--        </section>-->

<!--        <article class="shop-coupons container-fluid pl-0 pr-0" id="shop-coupons" v-if="!_.isEmpty(shop.vouchers)">-->
<!--            <div class="row line">-->
<!--                <h2 class="shop-coupons-title col-12">{{$t('Coupon codes')}}</h2>-->
<!--            </div>-->
<!--            <div class="swiper-container2">-->
<!--                <div class="swiper-wrapper row line flex-nowrap">-->
<!--                    <template v-for="voucher in shop.vouchers">-->
<!--                        <voucher-thumbnail :voucher="voucher" :slide="true"></voucher-thumbnail>-->
<!--                    </template>-->
<!--                </div>-->
<!--                <div class="swiper-pagination2"></div>-->
<!--            </div>-->
<!--        </article>-->

<!--        <article class="container-fluid shop-products" id="shop-products" v-if="!_.isEmpty(shop.sneakers)">-->
<!--            <div class="row line">-->
<!--                <h2 class="col-12 shop-products-title">{{$t('Products in our shop')}}</h2>-->
<!--            </div>-->
<!--            <div class="row line">-->
<!--                <client-only>-->
<!--                    <template v-for="sneaker in shop.sneakers">-->
<!--                        <sneaker-thumbnail :sneaker="sneaker"></sneaker-thumbnail>-->
<!--                    </template>-->
<!--                </client-only>-->
<!--            </div>-->
<!--            <div class="row line shop-products-footer">-->
<!--                <nuxt-link class="button-main" :to="redirectUrl" target="_blank" rel="nofollow">-->
<!--                    <arrow-right path-class/>-->
<!--                    <span class="button-main-text">{{$t('go-to-shop', {shop: shop.name})}}</span>-->
<!--                </nuxt-link>-->
<!--            </div>-->
<!--        </article>-->
<!--    </div>-->
</template>

<script>
    import SneakerThumbnail         from "../../../components/Sneakers/Thumbnail";
    import VoucherThumbnail         from "../../../components/Sneakers/VoucherThumbnail";
    import {mapMutations, mapState} from 'vuex'
    import SimpleHeader             from "../../../components/SimpleHeader";
    import staticMetasMixin         from "../../../plugins/staticMetasMixin"
    import ArrowRight               from "../../../components/icons/arrow-right";
    import Breadcrumbs from "../../../components/Breadcrumbs";
    import {btoaImplementation} from "@/plugins/redirectMixin";
    import UiBreadcrumbs from "@/components/v2/ui/UiBreadcrumbs.vue";
    import EmblaCarousel from "embla-carousel";
    import ReleaseThumbnail from "@/components/v2/thumbnails/release-thumbnail.vue";

    export default {
        name      : 'Shop',
        components: {
          ReleaseThumbnail,
          UiBreadcrumbs, ArrowRight, SimpleHeader, SneakerThumbnail, VoucherThumbnail, Breadcrumbs},
        props     : ['data'],
        mixins    : [staticMetasMixin],
        middleware: 'loadMetaNonQuery',
        data      : () => {
            return {
              rating    : 0,
              ratingInfo: '',
              emblaBrandApi: null,
              emblaProductApi: null,
              canBrandPrev: false,
              canBrandNext: false,
              canProductPrev: false,
              canProductNext: false,
            };
        },
        async asyncData({store, params, $axios})
        {
            try {
                let {data} = await $axios.get('shops/' + params.shop);
                return {shop: data}
            }
            catch (e) {
                console.log('shop ', e.response ? e.response.status : e)
            }
        },
        methods   : {
          handleBrandScroll(){
            this.canBrandPrev = this.emblaBrandApi.canScrollPrev()
            this.canBrandNext = this.emblaBrandApi.canScrollNext()
          },
          handleProductScroll(){
            this.canProductPrev = this.emblaProductApi.canScrollPrev()
            this.canProductNext = this.emblaProductApi.canScrollNext()
          },
          scrollBrandPrev() {
            this.emblaBrandApi?.scrollPrev()
          },
          scrollBrandNext() {
            this.emblaBrandApi?.scrollNext()
          },
          scrollProductPrev() {
            this.emblaProductApi?.scrollPrev()
          },
          scrollProductNext() {
            this.emblaProductApi?.scrollNext()
          },
        },
        computed  : {
            ...mapState({ currentRouteMetas: 'currentRouteMetas'
               , 'user': state=>state.auth.user}),
            hasImage()
            {
                return !this._.isEmpty(this.shop.images)
            },
            firstImage()
            {
                return this.hasImage ? this.shop.images[0] : null
            },
            description()
            {
                return this._.isEmpty(this.shop.description[this.$i18n.locale.toUpperCase()]) ?
                    this.shop.description[this.$i18n.locale] : ''
            },
            redirectUrl()
            {
                return this.localePath({name: 'new-go', query: {
                        shopId: this.shop.id,
                        url: btoaImplementation(this.linkToShop),
                      } });
            },
            linkToShop()
            {
                if (!this._.isEmpty(this.shop.affiliate_link)) {
                    if (this._.startsWith(this.shop.affiliate_link, '?')) {
                        return (this.shop.website + this.shop.affiliate_link)
                    }
                    return this.shop.affiliate_link + encodeURI(this.shop.website);
                }
                return this.shop.website;
            },
            breadcrumbItems(){
                return [
                    {text: 'Home', lnk: this.localePath('index')},
                    {text: this.$t('Shops') , lnk: this.localePath('shops')},
                    {
                        text: this.shop.name,
                        jsonText: this.$t('Shops') + ' ' + this.shop.name,
                        lnk: this.localePath('shops')
                    },

                ]
            },

            schemaBreadcrumbs() {
                let bItems = this.breadcrumbItems.map((item, index)=>{
                    return {
                        "@type": "ListItem",
                        "position": index + 1,
                        "item": {
                            "@id":process.env.appUrl + item.lnk,
                            "name": (item.jsonText ? item.jsonText: item.text)
                        }
                    }
                })
                return {
                    script: [
                        {
                            hid: 'breadcrumbs',
                            type: 'application/ld+json',
                            innerHTML: JSON.stringify({
                                "@context": "http://schema.org",
                                "@type": "BreadcrumbList",
                                "itemListElement": bItems
                            }, null, 2),
                        },
                    ]
                }
            }
        },
        mounted()
        {
          this.rating = +this.shop.rating;

          const options = { align: 'start', slidesToScroll: this.$device.isDesktop ? 4 : 2 }

          if(!this._.isEmpty(this.shop.brands)){
            const emblaBrandRef = this.$refs.emblaBrandRef
            console.log('emblaBrandRef', emblaBrandRef)
            this.emblaBrandApi = EmblaCarousel(emblaBrandRef, options)
            this.canBrandPrev = this.emblaBrandApi.canScrollPrev()
            this.canBrandNext = this.emblaBrandApi.canScrollNext()
            this.emblaBrandApi.on('scroll', this.handleBrandScroll)
          }

          if(!this._.isEmpty(this.shop.sneakers)){
            const emblaProductRef = this.$refs.emblaProductRef
            this.emblaProductApi = EmblaCarousel(emblaProductRef, options)
            this.canProductPrev = this.emblaProductApi.canScrollPrev()
            this.canProductNext = this.emblaProductApi.canScrollNext()
            this.emblaProductApi.on('scroll', this.handleProductScroll)
          }
        }
    }
</script>

<style lang="scss" scoped>
    h3, h4 {
        font-weight: inherit;
    }

    .swiper-container{

      .swiper-slide {
        text-align:center;
        display:flex; /* 내용을 중앙정렬 하기위해 flex 사용 */
        align-items:center; /* 위아래 기준 중앙정렬 */
        justify-content:center; /* 좌우 기준 중앙정렬 */
      }
      .swiper-slide img {
        box-shadow:0 0 5px #555;
      }
    }
</style>
