<script>
import EmblaCarousel from "embla-carousel";
import HomeSectionTitle from "./components/home-section-title.vue";
const shops = [
  {
    name: 'BSTN',
    image: require('~/assets/images/ui/shops/bstn.png'),
    link: '/shops/bstn'
  },
  {
    name: 'END',
    image: require('~/assets/images/ui/shops/end.png'),
    link: '/shops/end'
  },
  {
    name: 'Exclusive Items',
    image: require('~/assets/images/ui/shops/exclusivexitems.png'),
    link: '/shops/exclusivexitems'
  },
  {
    name: 'Flight Club',
    image: require('~/assets/images/ui/shops/flight-club.png'),
    link: '/shops/flight-club'
  },
  {
    name: 'Foot Locker',
    image: require('~/assets/images/ui/shops/foot-locker.png'),
    link: '/shops/foot-locker',
  },
  {
    name: 'Kicks Crew',
    image: require('~/assets/images/ui/shops/kicks-crew.png'),
    link: '/shops/kicks-crew'
  },
];

export default {
  name: "HomeBrandSection",
  components: {HomeSectionTitle},
  data() {
    return {
      emblaApi: null,
      canPrev: false,
      canNext: false,
      shops
    }
  },
  mounted() {
    const emblaRef = this.$refs.emblaRef
    const options = { align: 'start', slidesToScroll: this.$device.isDesktop ? 4 : 2 }

    this.emblaApi = EmblaCarousel(emblaRef, options)
    this.canPrev = this.emblaApi.canScrollPrev()
    this.canNext = this.emblaApi.canScrollNext()
    this.emblaApi.on('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(){
      this.canPrev = this.emblaApi.canScrollPrev()
      this.canNext = this.emblaApi.canScrollNext()
    },
    scrollPrev() {
      this.emblaApi?.scrollPrev()
    },
    scrollNext() {
      this.emblaApi?.scrollNext()
    }
  }
}
</script>

<template>
  <section class="flex flex-col items-center justify-center w-full bg-white border-b border-black">
    <HomeSectionTitle :title="$t('text.homePage.homeBrandsSectionTitle')" :subtitle="$t('text.homePage.homeBrandsSectionSubTitle')"/>
    <div class="relative w-full border-t border-[#131416]">
      <div ref="emblaRef" class="embla overflow-hidden">
        <div class="embla__container flex flex-row w-full flex-nowrap">
          <div class="embla__slide flex-none basis-1/2 lg:basis-1/4 aspect-square border-r border-[#131416] last:border-r-0"
               v-for="(shop, i) in shops" :key="i" >
            <nuxt-link :to="localePath(shop.link)" class="flex items-center justify-center w-full h-full">
              <img :src="shop.image" :alt="shop.name" class="w-40 h-40 object-contain"/>
            </nuxt-link>
          </div>
        </div>
      </div>
      <button @click="scrollPrev" class="absolute  h-8 w-8 rounded-full left-0 top-1/2 -translate-y-1/2" v-show="canPrev">
        <img src="~/assets/images/ui/home-swiper-next.png" alt="Previous" class="h-8 w-8 transform rotate-180"/>
      </button>
      <button @click="scrollNext" class="absolute   rounded-full right-0 top-1/2 -translate-y-1/2" v-show="canNext">
        <img src="~/assets/images/ui/home-swiper-next.png" alt="Previous" class="h-8 w-8"/>
      </button>
    </div>
  </section>
</template>
