<script>
import HomeSectionTitle from "./components/home-section-title.vue";
import HomeSectionAccordion from "./components/home-section-accodion.vue";
import HomeSectionProductSwiper from "./components/home-section-product-swiper.vue";

export default {
  name: "HomeStoreSneakersRankSection",
  components: {HomeSectionProductSwiper, HomeSectionAccordion, HomeSectionTitle},
  props: {
    products: {
      type: Array,
      default: () => []
    },
    totalShopCount: {
      type: Number,
      default: 0
    },
  },
  computed: {
    shops() {
      return this.products.map((shop) => {
        return {
          id: shop.id,
          name: shop.name,
          logo: shop.logo,
          slug: shop.slug
        }
      })
    },
    selectedProducts() {
      return this.products.find((shop) => shop.id === this.selectShopId)?.products || []
    },
  },
  created() {
    this.selectShopId = this.shops[0]?.id
  },
  data() {
    return {
      accordionVisible: false,
      selectShopId: null,
    }
  },
  methods: {
    selectShop(id) {
      this.selectShopId = id
    },
    setShowAccordion(value){
      this.accordionVisible = value;
    },
    showAccordion(){
      if(this.$device.isDesktop){
        this.accordionVisible = true
      }
    },
    hideAccordion(){
      if(this.$device.isDesktop){
        this.accordionVisible = false
      }
    },
    toggleShowAccordion(){
      if(!this.$device.isDesktop){
        this.accordionVisible = !this.accordionVisible;
      }
    }
  }
}
</script>

<template>
  <section
      class="w-full border-b border-black grid lg:grid-cols-4 grid-cols-1"
  >
    <div class="border-[#131416] border-r justify-between flex flex-col col-span-1">
      <HomeSectionTitle
          :title="$t('text.homePage.homeStoreSneakersRankSectionTitle')" :subtitle="$t('text.homePage.homeStoreSneakersRankSectionSubTitle')"/>
      <div class="flex flex-col w-full justify-end	" >
        <div class="border-black border-t py-4 px-6 flex items-center justify-between w-full">
          <span class="font-bold text-black text-lg w-full text-center">{{$t('text.homePage.stores')}}({{totalShopCount}})</span>
        </div>
        <HomeSectionAccordion :items="shops"
                              :use-show-accordion="true"
                              :show-accordion="accordionVisible"
                              :set-show-accordion="setShowAccordion"
                              :on-select-shop="selectShop"/>
      </div>
    </div>
    <div class="col-span-1 lg:col-span-3 flex">
      <HomeSectionProductSwiper :products="selectedProducts" :cols="$device.isDesktop ? 3 : 2" :rank="true"/>
    </div>
  </section>
</template>
