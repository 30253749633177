<script>
import EmblaCarousel from "embla-carousel";
import ProductThumbnail from "../../../thumbnails/product-thumbnail.vue";

export default {
  name: "home-section-product-swiper",
  components: {ProductThumbnail},
  props: {
    products: {
      type: Array,
      default: []
    },
    cols: {
      type: Number,
      default: 4
    },
    rank: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      emblaApi: null,
      canPrev: false,
      canNext: false,
    }
  },
  mounted() {
    const emblaRef = this.$refs.emblaRef
    const options = { align: 'start', slidesToScroll: this.cols }

    this.emblaApi = EmblaCarousel(emblaRef, options)
    this.canPrev = this.emblaApi.canScrollPrev()
    this.canNext = this.emblaApi.canScrollNext()
    this.emblaApi.on('scroll', this.handleScroll)
  },
  watch: {
    products() {
      this.emblaApi?.scrollTo(0)
    }
  },
  methods: {
    handleScroll(){
      this.canPrev = this.emblaApi.canScrollPrev()
      this.canNext = this.emblaApi.canScrollNext()
    },
    scrollPrev() {
      this.emblaApi?.scrollPrev()
    },
    scrollNext() {
      this.emblaApi?.scrollNext()
    }
  }
}
</script>
<template>
  <div class="relative w-full border-t border-[#131416] md:border-t-none">
    <div ref="emblaRef" class="embla overflow-hidden">
      <div class="embla__container flex flex-row w-full flex-nowrap">
        <div :class="{
          'embla__slide flex-none aspect-square border-r border-[#131416] last:border-r-0': true,

          'basis-1/6 lg:basis-1/6': cols === 6,
          'basis-1/5 lg:basis-1/5': cols === 5,
          'basis-1/2 lg:basis-1/4': cols === 4,
          'basis-1/3 lg:basis-1/3': cols === 3,
          'basis-1/2 lg:basis-1/2': cols === 2,
          'basis-1/1 lg:basis-1/1': cols === 1,
        }"
             v-for="(product, i) in products" :key="i" >
          <ProductThumbnail :product="product" :rank="rank ? i + 1 : 0"></ProductThumbnail>
        </div>
      </div>
    </div>
    <button @click="scrollPrev" class="absolute  h-8 w-8 rounded-full left-0 top-1/2 -translate-y-1/2" v-show="canPrev">
      <img src="~/assets/images/ui/home-swiper-next.png" alt="Previous" class="h-8 w-8 transform rotate-180"/>
    </button>
    <button @click="scrollNext" class="absolute   rounded-full right-0 top-1/2 -translate-y-1/2" v-show="canNext">
      <img src="~/assets/images/ui/home-swiper-next.png" alt="Previous" class="h-8 w-8"/>
    </button>
  </div>
</template>

<style scoped>

</style>