var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col w-full"},[_c('div',{class:{
    'border-black border-t py-4 px-6 flex items-center justify-between  w-full relative cursor-pointer': true,
    'underline': _vm.overItemIndex === null,
    'border-b': _vm.showAccordion
  },on:{"click":_vm.toggleShowAccordion}},[_c('span',{staticClass:"font-bold text-black text-lg w-full text-center"},[_vm._v(_vm._s(_vm.titleItem.name))]),_vm._v(" "),_c('div',{staticClass:"absolute right-6 top-1/2 transform -translate-y-1/2"},[_c('img',{staticClass:"w-[10px] h-[8px] transform transition-transform duration-500",class:{'rotate-180': _vm.showAccordion},attrs:{"src":require("assets/images/ui/icons/black-arrow-down.png"),"alt":"arrow-down"}})])]),_vm._v(" "),_c('ul',{staticClass:"flex flex-col w-full h-[0px] overflow-hidden transition-all duration-500",class:{'h-[300px]': _vm.showAccordion}},[_vm._l((_vm.withoutTitleItems),function(item,index){return _c('li',{key:index,staticClass:"flex flex-col w-full cursor-pointer border-black border-b",on:{"mouseover":function($event){return _vm.onOverItem(index)},"mouseleave":function($event){return _vm.onLeaveItem()},"click":function($event){return _vm.onAccordionClick(index)}}},[_c('div',{staticClass:"py-4 px-6 flex items-center justify-between w-full"},[_c('span',{class:{
          'text-black text-lg w-full text-center': true,
          'underline': _vm.overItemIndex === index,

        }},[_vm._v(_vm._s(item.name))])])])}),_vm._v(" "),_c('li',{staticClass:"flex flex-col w-full cursor-pointer",on:{"mouseover":function($event){return _vm.onOverItem(_vm.withoutTitleItems.length)},"mouseleave":function($event){return _vm.onLeaveItem()}}},[_c('nuxt-link',{staticClass:"py-4 px-6 flex items-center justify-between w-full",attrs:{"to":_vm.sneakerRootPage()}},[_c('span',{class:{
          'text-black text-lg w-full text-center': true,
          'underline': _vm.overItemIndex === _vm.withoutTitleItems.length,
        }},[_vm._v(_vm._s(_vm.$t('text.homePage.moreStores')))])])],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }